<template>
  <form
    class="ui-form"
    @submit.prevent="submit"
  >
    <template class="contents">
      <slot></slot>
    </template>
  </form>
</template>

<script>
export default {
  name: 'ui-form',

  computed: {
    fields() {
      return this.$children.filter(vnode => vnode.$options.name == 'ui-field');
    }
  },

  methods: {
    submit() {
      let hasErrors = false;

      this.fields.forEach(field => {
        // field.setMessage('form was here 2');

        try {
          field.validate();
        } catch (errors) {
          console.log('errors in field', errors);
          hasErrors = true;
        }
      });

      if (!hasErrors) {
        console.log("$emit('submit')");
        this.$emit('submit');
        return false;
      }

      console.log("$emit('errors')");
      this.$emit('errors');
      return false;
    }
  }
};
</script>